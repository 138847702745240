@use "mixins/breakpoint";
@use "variables/color";

.ratingBadge {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

.badgeWrapper {
  position: relative;
  width: 42px;
  height: 42px;
  color: color.$almostBlack;
  font-size: 14px;

  > svg {
    width: 100%;
    height: 100%;
  }

  @include breakpoint.tablet {
    width: 48px;
    height: 48px;
    font-size: 16px;
  }
}

.badgeContent {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: color.$white;
}

.noRatingIcon {
  width: 40%;
  height: 40%;
}
