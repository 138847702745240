@use "mixins/breakpoint";

.audiobook {
  position: relative;
}

.bgImage {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  transform: scale(1.1);

  &__blur {
    filter: blur(25px) brightness(0.8);
  }
}

.main {
  isolation: isolate;
  background: #fff;
  color: #000;
  overflow: hidden;
  position: relative;

  &::before {
    position: absolute;
    inset: 0;
    background-image: linear-gradient(rgba(22, 8, 41, 0.4), #160829);
    content: "";
    z-index: 2;
  }

  &__dark {
    background: #222;
    color: #fff;

    &::before {
      background-image: none;
    }
  }
}

.content {
  position: relative;
  z-index: 2;
  background: #fff;
  border-radius: 16px 16px 0 0;

  @include breakpoint.laptop {
    padding-top: 32px;
    border-radius: 24px 24px 0 0;
  }

  &__dark {
    background: rgba(0, 0, 0, 0.3);
  }
}

.priceContainer {
  margin-bottom: 16px;

  @include breakpoint.laptop {
    display: none;
  }
}

.sectionSeparator {
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  margin: 24px 0;
}

.notAvailable {
  display: none;

  @include breakpoint.laptop {
    display: block;
  }
}
