@use "mixins/breakpoint";

.button {
  display: flex;
  align-items: center;
  width: 130px;
  padding: 0;
  background: transparent;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-transform: none;
  text-align: left;
  transition: color 0.25s ease;

  &:hover {
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    color: #33adff;
  }

  @include breakpoint.laptop {
    width: auto;
    white-space: nowrap;
  }
}

.mobileText {
  @include breakpoint.laptop {
    display: none;
  }
}

.desktopText {
  display: none;

  @include breakpoint.laptop {
    display: inline;
  }
}
