.starIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  margin-left: 5px;
  font-size: 12px;
}

.icon {
  position: absolute;
  height: inherit;
  width: inherit;
  color: #fff;
}

.label {
  position: relative;
  font-size: inherit;
  font-weight: 400;
  color: #160829;
}
