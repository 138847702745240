@use "variables/color";
@use "mixins/breakpoint";

.clubMember {
  display: none;

  @include breakpoint.laptop {
    display: grid;
  }
}

.contentBox {
  padding: 12px 16px;
  border-color: color.$veryLightGray;
}

.contentBoxInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.subTitle {
  margin: 0;
  color: color.$almostBlack;
  justify-content: start;
}

.shelfIcon {
  min-width: 24px;
  margin-right: var(--column-gap);
}

.noClubPrice {
  text-align: center;
}

.lowestPrice {
  text-align: center;
}
