@use "variables/color";
@use "mixins/breakpoint";

.clubMemberMobile {
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 4px;
  background: #f6f6f6;
  border: 1px solid color.$gray;

  @include breakpoint.laptop {
    display: none;
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.title {
  color: color.$almostBlack;
  text-align: center;
  margin: 0;
}

.contentWrapper {
  display: grid;

  grid-template-rows: 0fr;
  opacity: 0;
  transition: grid-template-rows 300ms, opacity 600ms 200ms;

  &__visible {
    grid-template-rows: 1fr;
    opacity: 1;
  }

  & > * {
    overflow: hidden;
  }
}

.content {
  display: grid;
  margin-top: 14px;
  padding: 12px;
  gap: 12px;
  border-top: 1px solid color.$gray;
}

.price {
  margin: 0;

  &__club {
    color: color.$club;
  }
}

.oldPrice {
  text-decoration: line-through;
  margin-right: 4px;
  color: #757575;
}

.description {
  margin: 0;
  color: #757575;
}

.noClubPrice {
  text-align: center;
}

.lowestPrice {
  text-align: center;
}
