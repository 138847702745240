.details {
  color: #fff;
  display: block;
}

.detailsTable {
  --vertical-margin: 16px;
  margin-top: var(--vertical-margin);
  margin-bottom: var(--vertical-margin);

  td {
    line-height: 28px;
    padding: 3px;
    vertical-align: top;

    &:first-child {
      padding-right: 15px;
    }
  }
}

.tagsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;
}
