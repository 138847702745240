@use "mixins/breakpoint";

.shelfIcon {
  min-width: 24px;
  margin-right: 4px;
}

.familyShare {
  margin: 0;
  text-align: center;
  color: #757575;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px;
  background-color: transparent;
  border-radius: 4px;
}

.shelfButton {
  gap: 4px;
}
