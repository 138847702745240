@use "variables/color";
@use "mixins/breakpoint";

.reviewsBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.reviews {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include breakpoint.tablet {
    gap: 24px;
  }
}

.showMore {
  width: 100%;

  @include breakpoint.tablet {
    width: fit-content;
  }
}

.reviewsInfo {
  color: #757575;
  margin-bottom: 30px;

  @include breakpoint.tablet {
    margin-bottom: 60px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 90px;
  }
}

.reviewsInfoLink {
  color: inherit;
}
