@use "mixins/breakpoint";

.description {
  margin: 0 0 8px;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;
  list-style: none;
  margin: 0;
  padding: 0;

  @include breakpoint.laptop {
    align-self: start;
    margin-top: auto;
  }

  svg {
    margin-right: 4px;
  }
}
