@use "mixins/breakpoint";

.notAvailable {
  padding: 12px;
  margin-top: 0;
  background: #fff;
  box-shadow: 0px 20px 20px 0px #0000000a;
  text-align: center;
  border-radius: 8px;
}

.paragraph {
  color: #757575;
  margin: 0 auto;
  width: 180px;
}
