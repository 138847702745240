@use "mixins/breakpoint";

.playButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 4px;
  margin-top: 16px;

  @include breakpoint.laptop {
    margin-top: 12px;
  }
}

.playButton {
  gap: 4px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}
