@use "variables/color";

.options {
  display: flex;
  flex-direction: column;
}

.summary {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  color: color.$primaryBlue;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.content {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition: grid-template-rows 300ms, opacity 600ms 200ms;

  &__visible {
    grid-template-rows: 1fr;
    opacity: 1;
  }

  & > * {
    overflow: hidden;
  }
}

.childrenWrapper {
  > * {
    margin-top: 8px;
  }
}
