@use "variables/color";

.tag {
  display: inline-flex;
  align-items: center;
  padding: 3px 5px;
  border: solid 1px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: color.$white;

  &[href] {
    transition: color 0.25s ease, background-color 0.25s ease;

    &:hover {
      border-color: rgba(51, 173, 255, 0.2);
      color: color.$primaryBlue;
    }
  }
}
