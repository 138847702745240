@use "mixins/breakpoint";
@use "variables/color";

.box {
  --box-content-gap: 16px;
  position: relative;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;
  padding: 16px 0;
  background: color.$white;
  border-radius: 16px;
  z-index: 2;

  @include breakpoint.tablet {
    border-radius: 8px;
  }

  @include breakpoint.laptop {
    --box-content-gap: 12px;
    padding: 12px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.04);
  }
}

.boxContent {
  display: flex;
  flex-direction: column;
  gap: var(--box-content-gap);
  border-radius: 4px;
  padding: 12px 16px;
  border: 1px solid color.$gray;
  background: #f6f6f6;

  @include breakpoint.laptop {
    padding: 12px;
  }
}

.boxContentTitle {
  margin: 0;
  color: color.$almostBlack;

  &__club {
    color: color.$club;
  }
}

.boxContentSubtitle {
  margin: 0;
  color: #757575;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  --column-gap: 8px;
  margin: 0;
  text-align: center;
}

.price {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  color: #160829;
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  margin: 0;

  & > & {
    margin-right: 8px;
  }

  &__club {
    color: color.$club;
  }

  &__old {
    color: #757575;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-decoration: line-through;
  }
}

.footerParagraph {
  margin: 0;
  color: #757575;
  text-align: center;
}
