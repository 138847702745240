@use "variables/color";

.option {
  text-align: left;
  cursor: pointer;
  --animated-part-template-rows: 0fr;

  .boxContent {
    background-color: color.$white;
    border-color: color.$lightGray;

    .radioButton {
      color: color.$darkGray;
    }

    .checkedRadioButton {
      display: none;
    }
    .uncheckedRadioButton {
      display: block;
    }
  }

  &__checked {
    --animated-part-template-rows: 1fr;
    .boxContent {
      background-color: #f6f6f6;
      border-color: color.$gray;

      .radioButton {
        color: color.$primaryBlue;
      }
      .checkedRadioButton {
        display: block;
      }
      .uncheckedRadioButton {
        display: none;
      }
    }
  }
}

.boxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.radioButton {
  align-self: flex-start;
}

.oldPrice {
  color: #757575;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: line-through;
  margin-right: 4px;
}

.lowestPrice {
  margin-top: 10px;
  text-align: center;
}

.animationWrapper {
  display: grid;
  grid-template-rows: var(--animated-part-template-rows);
  overflow: hidden;
  transition: grid-template-rows 0.4s ease, margin-top 0.4s ease;

  &__hidden {
    margin-top: calc(var(--box-content-gap) * -1);
  }
}

.contentWrapper {
  overflow: hidden;
  display: grid;
}
