@use "variables/color";
@use "mixins/breakpoint";

.description {
  color: color.$white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin: 0;
}

.infoIcon {
  @include breakpoint.laptop {
    display: none;
  }
}
