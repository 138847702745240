@use "variables/color";

.contentBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 12px 16px;
  background: #f6f6f6;
  border-radius: 4px;
  border: 1px solid color.$veryLightGray;
}

.header {
  margin: 0;
}

.paragraph {
  margin: 0;
  text-align: center;
  max-width: 220px;
  color: #757575;
}

.downloadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto 1fr;
  gap: 4px;
  color: #33adff;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;

  &:hover {
    color: #5bbdff;
  }
}
