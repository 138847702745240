@use "variables/color";

.clubButtonWrapper {
  display: flex;
  flex-direction: column;
}

a.clubButton {
  gap: 4px;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
}

.trial {
  margin: 0;
  color: color.$violet;
  margin-top: 4px;
  text-align: center;
}

button.addToCartButton {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.playButton {
  gap: 4px;
  cursor: pointer;
}
