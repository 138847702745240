.list {
  --gap: 8px;

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  & + & {
    margin-top: var(--gap);
  }
}
