@use "mixins/breakpoint";

.freeProduct {
  display: none;

  @include breakpoint.laptop {
    display: grid;
    gap: 16px;
  }
}
