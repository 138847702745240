@use "variables/color";
@use "mixins/breakpoint";

.reviewBoxTop {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  grid-template-areas:
    "title title"
    "badge badge"
    "action action";

  @include breakpoint.tablet {
    grid-template-areas:
      "title action"
      "badge badge";
  }
}

.title {
  grid-area: title;
}

.badge {
  grid-area: badge;
}

.action {
  grid-area: action;
  display: flex;
  justify-content: flex-end;
}

.rateButton {
  @include breakpoint.tablet {
    max-width: 280px;
  }
}

.buyToRate {
  color: color.$darkGray;
  @include breakpoint.tablet {
    text-align: end;
  }
}

.login {
  @include breakpoint.tablet {
    text-align: end;
  }
}

.loginLink {
  color: color.$primaryBlue;
  text-decoration: none;
  font-weight: bold;
}
