@use "mixins/breakpoint";
@use "variables/color";

.favouritesButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;
  color: color.$white;
  text-transform: none;
  font-size: 0;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: none;
  }

  &:not(:disabled):hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  @include breakpoint.laptop {
    white-space: nowrap;
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 14px;
    width: 184px;
  }
}

.icon {
  @include breakpoint.laptop {
    margin-right: 4px;
  }
}
