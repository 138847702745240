@use "mixins/breakpoint";
@use "variables/color";

.productTop {
  isolation: isolate;
  position: relative;
  z-index: 3;
}

.productTopRow {
  padding-top: 32px;

  @include breakpoint.laptop {
    padding-bottom: 32px;
  }
}

.authors {
  text-align: center;

  @include breakpoint.laptop {
    text-align: left;
  }
}

.author {
  color: color.$white;
  text-decoration: underline;
  font-size: 14px;
  line-height: 20px;
}

.comma {
  color: color.$white;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}

.cover {
  border-radius: 8px;
  display: flex;
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;

  @include breakpoint.laptop {
    border-radius: 8px 8px 0px 0px;
  }
}

.detailsColumn {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  @include breakpoint.laptop {
    margin-top: 0;
  }
}

.title {
  color: color.$white;
  font-weight: 700;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  @include breakpoint.laptop {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    margin-bottom: 8px;
  }
}

.playButton {
  &__mobile {
    margin-top: 16px;

    @include breakpoint.laptop {
      display: none;
    }
  }

  &__desktop {
    display: none;

    @include breakpoint.laptop {
      display: flex;
    }
  }
}

.notAvailableMobile {
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;

  @include breakpoint.laptop {
    display: none;
  }
}

.actions {
  display: flex;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  justify-content: space-between;

  @include breakpoint.laptop {
    justify-content: flex-start;
  }
}
