.aiReviewWrapper {
  padding: 1px;
  background: linear-gradient(91deg, #33adff 7.94%, #5033ff 115.17%);
  border-radius: 9px;
}

.aiReview {
  padding: 16px;
  border-radius: 8px;
  background: #f8f7f8;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  border-radius: 4px;
  background: linear-gradient(111deg, #d619ff -49.21%, #33adff 111.58%);
  height: 40px;
  width: 40px;
  padding: 8px;
}
