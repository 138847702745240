@use "variables/color";

.argumenterList {
  display: grid;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 4px;
}

.argumenter {
  color: color.$almostBlack;
  display: flex;
  gap: 6px;

  .label {
    flex: 1;
  }
}
