@use "variables/color";

.breadcrumbs {
  background: color.$darkViolet;
}

.list {
  align-items: stretch;
  color: color.$white;
  column-gap: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 0;
  }
}

.item {
  align-items: center;
  color: color.$white;
  display: flex;
  font-size: 16px;
  line-height: 38px;
  text-decoration: none;
  white-space: nowrap;

  &[href] {
    color: color.$primaryBlue;

    &:hover {
      color: color.$white;
    }

    &:active {
      background: color.$primaryBlue;
    }
  }
}

.separator {
  align-self: center;
  color: color.$darkGray;
  min-width: 14px;
}
