@use "variables/color";
@use "mixins/breakpoint";

.reviewItem {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d9ecf9;

  &__accent {
    border-color: color.$violet;
  }
}

.header {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto;
  @include breakpoint.tablet {
    grid-template-columns: 1fr auto auto;
  }

  grid-template-areas:
    "author date"
    "stars stars";

  @include breakpoint.tablet {
    grid-template-areas: "author stars date";
  }
}

.author {
  grid-area: author;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
}

.authorIcon {
  display: flex;
  width: fit-content;
  padding: 6px;
  border-radius: 100%;
  background-color: color.$violet;
  color: #fff;
}

.authorName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.stars {
  grid-area: stars;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #ffa42e;
}

.reviewDate {
  grid-area: date;
  color: #635a70;
  display: flex;
  align-items: center;
}

.review {
  word-break: break-word;
}
