@use "mixins/breakpoint";

.content {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition: grid-template-rows 300ms, opacity 600ms 200ms;

  &__visible {
    grid-template-rows: 1fr;
    opacity: 1;

    // we need to add margin to last child of content for mobile
    & > * > :last-child {
      margin-bottom: 32px;

      @include breakpoint.laptop {
        margin-bottom: 0;
      }
    }
  }

  & > * {
    overflow: hidden;
  }

  @include breakpoint.laptop {
    flex: 1;
    display: block;
    transition: none;
    opacity: 1;

    & > * {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.button {
  background-color: unset;
  border-radius: unset;
  color: #fff;
  border-top: solid 1px rgba(187, 183, 197, 0.3);
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  padding: 12px 0;
  text-align: left;

  &:hover {
    background-color: unset;
    box-shadow: none;
    cursor: pointer;
  }

  &::after {
    border: solid #bbb7c5;
    border-width: 0 2px 2px 0;
    content: "";
    display: block;
    height: 10px;
    margin-right: 3px;
    transition: transform 0.2s ease;
    width: 10px;
    transform: translateY(-25%) rotateZ(45deg);
  }

  &__visible {
    &::after {
      transform: translateY(25%) rotateZ(-135deg);
    }
  }

  @include breakpoint.laptop {
    display: none;
  }
}
