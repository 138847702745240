@use "mixins/breakpoint";
@use "variables/color";

.buyToRate {
  color: #757575;
  @include breakpoint.tablet {
    flex-basis: 400px;
    text-align: right;
  }
}

.loginToRate {
  @include breakpoint.tablet {
    flex-basis: 400px;
    text-align: right;
  }
}

.loginLink {
  display: inline-flex;
  color: color.$primaryBlue;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  @include breakpoint.tablet {
    line-height: 24px;
  }
}

.ratingSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  border: solid color.$gray;
  border-width: 1px 0;
  padding: 16px 0;

  @include breakpoint.tablet {
    flex-direction: row;
    align-items: center;
  }
}

.rateButton {
  @include breakpoint.tablet {
    max-width: 280px;
  }
}
