@use "variables/color";

.description {
  line-height: 18px;

  overflow: hidden;

  &__extendable {
    max-height: 180px;
  }

  &__extended {
    max-height: none;
  }

  > p {
    margin: 18px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  @media (min-width: 1024px) {
    max-height: none;
  }
}

.extendButton {
  align-items: center;
  color: color.$violet;
  cursor: pointer;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, auto);
  margin: 18px auto;
  text-decoration: underline;
  width: fit-content;

  &::after {
    border: solid currentColor;
    border-width: 0 2px 2px 0;
    content: "";
    display: block;
    height: 10px;
    transform: translateY(-25%) rotateZ(45deg);
    transition: all 0.2s ease;
    width: 10px;
  }

  &__extended {
    &::after {
      transform: translateY(25%) rotateZ(-135deg);
    }
  }

  @media (min-width: 1024px) {
    display: none;
    max-height: none;
  }
}
