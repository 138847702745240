@use "mixins/breakpoint";

.howToListen {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include breakpoint.tablet {
    gap: 16px;
  }
}

.appLinks {
  display: flex;
  gap: 8px;
}

.linkItem {
  display: flex;
}

.image {
  width: auto;
}

.devices {
  display: flex;
  gap: 16px;
}

.device {
  display: flex;
}
