@use "variables/color";

.subtitle {
  margin: 0;
  color: color.$almostBlack;
}

.noClubPrice {
  text-align: center;
}
