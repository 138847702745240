@use "ui/colors";

.alert {
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  overflow: hidden;

  &__info {
    background: colors.$purple-500;
  }

  &__error {
    background: colors.$red-light;
  }

  &__centered {
    text-align: center;
  }

  a {
    color: colors.$blue-500;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      color: #fff;
    }
  }
}
